<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    上好课 来牛人学院
                </div>
                <div class="findlanguage">
                    Take good classes and come to the Master Academy
                </div>
            </div>
            <div class="boxcopy">
                <div class="cardboxt">
                    <el-carousel height="270px" autoplay="false">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <div class="cardcarousel">
                                <div class="cardcompany" v-for="(item, index) in listdatalawyer" :key="index">
                                    <div class="boxleft">
                                        <img style="width:270px; height: 150px;" :src=item.img alt="">
                                    </div>
                                    <div class="lefttitle">
                                        软件基础课程
                                    </div>
                                    <div class="leftarticle">
                                        <div>
                                            @设计名师
                                        </div>
                                        <div class="rightbox">
                                            <div style="padding: 0 5px;">
                                                <img style="width: 13px;height:13px" src="../../assets/home/play.png"
                                                    alt="">
                                            </div>
                                            <p>6554</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            labellist: [
                {
                    title: '成立10年',
                    id: 0,
                },
                {
                    title: '知识产权',
                    id: 1,
                },
                {
                    title: '刑事诉讼',
                    id: 2,
                },
                {
                    title: '合同纠纷',
                    id: 3,
                },
            ],
            listdata: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 2
                },

            ],
            listdatalawyer: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/sidetwo.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/bottomsix.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/bottomnine.png'),
                    id: 2
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/bottomseven.png'),
                    id: 3
                },
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #F4F4F4;
        height: 500px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #808080;
                font-size: 13px;
            }
        }

        .boxcopy {
            width: 1200px;
            margin: 0 auto;
            // height: 300px;

            // background: salmon;
            .cardboxt {
                width: 1250px;
                // height: 300px;
                background: none;
                margin: 10px 0;


                // display: flex;
                // flex-direction: row;
                .cardcarousel {
                    width: 1200px;
                    // height: 300px;
                    // border: 1px solid rgb(0, 255, 34);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .cardcompany {
                        width: 270px;
                        // height: 150px;
                        // margin: 10px auto;
                        // display: flex;
                        // justify-content: space-between;
                        // border: 1px solid #C9C9C9;
                        // box-shadow: 1px 2px 3px #c5c5c4;

                        .boxleft {
                            width: 270px;
                            // height:120px;
                            // border: 1px solid firebrick;

                            // padding: 10px 10px;
                        }

                        .lefttitle {
                            font-size: 15px;
                            padding: 0 5px;

                        }

                        .leftarticle {
                            font-size: 10px;
                            padding: 5px 0;
                            // border: 1px solid forestgreen;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;


                            .rightbox {
                                // border: 1px solid gold;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                            }
                        }



                    }
                }
            }

        }
    }

}
</style>
