<template>
    <div class="m-slide" @mouseenter="onStop" @mouseleave="onStart">
        <div :style="`will-change: transform; transform: translateX(${-left}px);`" class="u-slide-title"
            v-for="(item, index) in sliderData" :key="index" :title="item.title" @click="onClickTitle(item.title)">
            {{ item.title }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'Slider',
    watch: {
        sliderData: {
            handler: function (newVal, oldVal) {
                // 在属性发生变化时执行的逻辑
                console.log(newVal,oldVal)
            },
        }
    },
    props: {
        sliderData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            left: 0,
            moveTimer: null
        }
    },
    created() {
        this.onStart()
    },
    methods: {
        onClickTitle(title) { // 通知父组件点击的标题
            this.$emit('getTitle', title)
        },
        onStop() { // 暂停滚动
            clearInterval(this.moveTimer)
        },
        onStart() { // 开始滚动
            if (this.sliderData.length > 4) { // 超过4条开始滚动
                this.getSlide()
            }
        },
        getSlide() {
            this.moveTimer = setInterval(() => {
                this.moveLeft()
            }, 5) // 1s移动200次
        },
        moveLeft() {
            if (this.left >= 320) {
                this.sliderData.push(this.sliderData.shift()) // 将第一条数据放到最后
                this.left = 0
            } else {
                this.left += 0.2 // 每次移动0.2px
            }
        }
    }
}
</script>
<style lang="less" scoped>
.m-slide {
    width: 400px;
    // text-align: center;
    height:13px;
    line-height: 13px;
    white-space: nowrap;
    overflow: hidden;
    // border: 1px solid rgb(255, 0, 0);

    .u-slide-title {
        display: inline-block;
        margin-left: 20px;
        // font-size: 10px;
        // font-weight: 400;
        line-height: 10px;
        color: #FFF;
        // width: 300px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        cursor: pointer;
        text-align: center;
        &:hover {
            color: #1890FF;
        }
    }
}
</style>