import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "about" */ '../views/Memberlogin.vue')
  },
  {
    path: '/Registered',
    name: 'Registered',
    component: () => import(/* webpackChunkName: "about" */ '../views/Registered.vue')
  },
  {
    path: '/copyrightregistration',
    name: 'copyrightregistration',
    redirect: '/copyrightregistration/copyrightservice',
    component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightregistration.vue'),
    children: [
      {
        path: '/copyrightservice',
        name: 'copyrightservice',
        component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightservice.vue'),
      },
      // /*版权登记
      {
        path: 'copyreightdetails',
        name: 'copyreightdetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyreightdetails.vue'),
        children: [
          {
            path: 'copyrightsubmit',
            name: 'copyrightsubmit',
            component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightsubmit.vue'),
          },
        ]
      },
      {
        path: 'copyreightchange',
        name: 'copyreightchange',
        component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightchage.vue'),
        children: [
          {
            path: 'copyrightsubmit',
            name: 'copyrightsubmit',
            component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightsubmit.vue'),
          },
        ]
      },
      {
        path: 'copyrighttransfer',
        name: 'copyrighttransfer',
        component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrighttransfer.vue'),
        children: [
          {
            path: 'copyrightsubmit',
            name: 'copyrightsubmit',
            component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyrightsubmit.vue'),
            children: [
              {
                path: 'orderpayment',
                name: 'orderpayment',
                component: () => import(/* webpackChunkName: "about" */ '../views/copyright/orderpayment.vue'),
                children: [
                  {
                    path: 'serviceprogress',
                    name: 'serviceprogress',
                    component: () => import(/* webpackChunkName: "about" */ '../views/copyright/serviceprogress.vue'),
                  }
                ]
              },
            ]
          },
        ]
      },
      // */
      // 商标注册
      {
        path: 'Trademarkegistering',
        name: 'Trademarkegistering',
        component: () => import(/* webpackChunkName: "about" */ '../views/Trademarkregistering/Trademarkegistering.vue'),
      },
      {
        path: 'Trademarkdesign',
        name: 'Trademarkdesign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Trademarkregistering/Trademarkdesign.vue'),
      },
      {
        path: 'Trademarkpurchase',
        name: 'Trademarkpurchase',
        component: () => import(/* webpackChunkName: "about" */ '../views/Trademarkregistering/Trademarkpurchase.vue'),
      },
      {
        path: 'Trademarkmarket',
        name: 'Trademarkmarket',
        component: () => import(/* webpackChunkName: "about" */ '../views/Trademarkregistering/Trademarkmarket.vue'),
      },

    ]
  },
  {
    path: '/legaladvice',
    name: 'legaladvice',
    component: () => import(/* webpackChunkName: "about" */ '../views/legaladvice/legaladvice.vue'),
    children: [
      {
        path: 'lawfirm',
        name: 'lawfirm',
        component: () => import(/* webpackChunkName: "about" */ '../views/legaladvice/lawfirm.vue'),
        children: [
          {
            path: 'LawFirmConsulting',
            name: 'LawFirmConsulting',
            component: () => import(/* webpackChunkName: "about" */ '../views/legaladvice/LawFirmConsulting.vue'),

          }
        ]

      },
      {
        path: 'lawyer',
        name: 'lawyer',
        component: () => import(/* webpackChunkName: "about" */ '../views/legaladvice/lawyer.vue'),
        children: [
          {
            path: 'lawyerconsultation',
            name: 'lawyerconsultation',
            component: () => import(/* webpackChunkName: "about" */ '../views/legaladvice/lawyerconsultation.vue'),

          }
        ]
      },
    ]
  },
  // 求职招聘
  {
    path: '/Jobserach',
    name: 'Jobserach',
    component: () => import(/* webpackChunkName: "about" */ '../views/Jobsearch/Jobsearch.vue'),
  },
  // 设计商城
  {
    path: '/dedignpublic',
    name: 'dedignpublic',
    redirect: '/dedignpublic/designmall',
    component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/dedignpublic.vue'),
    children: [
      {
        path: '/designmall',
        name: 'designmall',
        component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/designmall.vue'),
      },
      {
        path: 'designmalldetails',
        name: 'designmalldetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/introduceddetails.vue'),
      },
      {
        path: 'programmedetails',
        name: 'programmedetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/programmedetails.vue'),
        children: [
          {
            path: 'programmesubmit',
            name: 'programmesubmit',
            component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/programmesubmit.vue'),
            children: [
              {
                path: 'mallorderpayment',
                name: 'mallorderpayment',
                component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/mallorderpayment.vue'),
              }

            ],
          },
          {
            path: 'mallshops',
            name: 'mallshops',
            component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/mallshops.vue'),

          }
        ]
      },
      {
        path: 'malltakeorder',
        name: 'malltakeorder',
        component: () => import(/* webpackChunkName: "about" */ '../views/Designmall/malltakeorder.vue'),

      }
    ]
  },
  // 多媒体商城
  {
    path: '/Multimediamallpubilc',
    name: 'Multimediamallpubilc',
    component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/Multimediamallpubilc.vue'),
    redirect: '/Multimediamallpubilc/Multimediamall',
    children: [
      {
        path: '/Multimediamall',
        name: 'Multimediamall',
        component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/Multimediamall.vue'),
      },
      {
        path: 'Multimediamalldetails',
        name: 'Multimediamalldetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/Multimediamalldetails.vue'),
        children: [
          {
            path: 'Multimediamallsubmit',
            name: 'Multimediamallsubmit',
            component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/Multimediamallsubmit.vue'),
            children: [
              {
                path: 'hardwareorderpayment',
                name: 'hardwareorderpayment',
                component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/hardwareorderpayment.vue'),
              }
            ]
          },
          {
            path: 'Multimediamallshop',
            name: 'Multimediamallshop',
            component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/Multimediamallshop.vue'),
            children: [
              {
                path: 'shoppingcart',
                name: 'shoppingcart',
                component: () => import(/* webpackChunkName: "about" */ '../views/Multimediamall/shoppingcart.vue'),
              }
            ]
          }
        ]
      }
    ]
  },
  // 原创设计案例
  {
    path: '/Originalcasepublic',
    name: 'Originalcasepublic',
    component: () => import(/* webpackChunkName: "about" */ '../views/Originalcase/Originalcase.vue'),
    redirect: '/Originalcasepublic/Originalcase',
    children: [
      {
        path: '/Originalcase',
        name: 'Originalcase',
        component: () => import(/* webpackChunkName: "about" */ '../views/Originalcase/Originalcase.vue'),
      },
      {
        path: 'Originalcasedetails',
        name: 'Originalcasedetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Originalcase/Originalcasedetails.vue'),
      }
    ]
  },
  // 软件天地
  {
    path: '/SoftwareWorldpubilc',
    name: 'SoftwareWorldpubilc',
    component: () => import(/* webpackChunkName: "about" */ '../views/SoftwareWorld/SoftwareWorldpubilc.vue'),
    redirect: '/SoftwareWorldpubilc/SoftwareWorld',
    children: [
      {
        path: '/SoftwareWorld',
        name: 'SoftwareWorld',
        component: () => import(/* webpackChunkName: "about" */ '../views/SoftwareWorld/SoftwareWorld.vue'),
      },
      {
        path: 'SoftwareWorlddetails',
        name: 'SoftwareWorlddetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/SoftwareWorld/SoftwareWorlddetails.vue'),
        children: [
          {
            path: 'SoftwareWorldpagedetails',
            name: 'SoftwareWorldpagedetails',
            component: () => import(/* webpackChunkName: "about" */ '../views/SoftwareWorld/SoftwareWorldpagedetails.vue'),
          }
        ]
      }
    ]
  },
  // 牛人学院
  {
    path: '/Niurencollegepublic',
    name: 'Niurencollegepublic',
    component: () => import(/* webpackChunkName: "about" */ '../views/Niurencollege/Niurencollegepublic.vue'),
    redirect:'/Niurencollegepublic/Niurencollege',
    children:[
      {
        path: '/Niurencollege',
        name: 'Niurencollege',
        component: () => import(/* webpackChunkName: "about" */ '../views/Niurencollege/Niurencollege.vue'),
      },
      {
        path: 'software',
        name: 'software',
        component: () => import(/* webpackChunkName: "about" */ '../views/Niurencollege/software.vue'),
        children:[
          {
            path: 'Niurencollegedetails',
            name: 'Niurencollegedetails',
            component: () => import(/* webpackChunkName: "about" */ '../views/Niurencollege/Niurencollegedetails.vue'),
          }
        ]
      }
    ]
  }


  // {
  //   // children: [

  //   path: '/copyrightregistration',
  //   name: 'copyrightregistration',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/copyrightregistration.vue'),

  //   // ]

  //   children: [
  //     {
  //       path: 'copyreightdetails',
  //       name: 'copyreightdetails',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/copyright/copyreightdetails.vue'),
  //     }
  //   ]
  // },




]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      // 模拟异步操作
      setTimeout(() => {
        // 如果有保存的滚动位置，则恢复到保存的位置
        if (savedPosition) {
          resolve(savedPosition);
        }
        // 否则，滚动到新页面的顶部
        else {
          resolve({ x: 0, y: 0 });
        }
      }, 1000); // 假设异步操作需要 1 秒完成
    });
  }
})
// router.beforeEach((to, from, next) => {
//   localStorage.setItem('currentPathName', to.name)
//   // 触发store的数据更新
//   store.commit('setPath')
//   // 其他的情况都放行
//   next()
// })
export default router
