<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    专业的版权知产服务
                </div>
                <div class="findlanguage">
                    Professional intellectual property servi
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox" v-for="(item, index) in listdata" :key="index">
                    <div class="oneimg">
                        <img style="width:90px;height:90px;" :src=item.img alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrighttwo',
    data() {
        return {
            listdata: [
                {
                    img: require('../../assets/home/Bone.png'),
                    id: 0
                },
                {
                    img: require('../../assets/home/Btwo.png'),
                    id: 1
                },
                {
                    img: require('../../assets/home/Bthree.png'),
                    id: 2
                },
                {
                    img: require('../../assets/home/Bfour.png'),
                    id: 3
                },
                {
                    img: require('../../assets/home/Bfive.png'),
                    id: 4
                },
                {
                    img: require('../../assets/home/Bsix.png'),
                    id: 5
                }
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #fff;
        height: 400px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding:70px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #808080;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height:100px;
            // border: 1px solid khaki;
            display: flex;
                justify-content: space-between;

            .allbox {
                width: 100px;
                
                // border: 1PX solid rgb(102, 255, 0);

                // .oneimg {
                //     // padding:20px 20px;
                //     // border: 1px solid magenta;

                // }
            }
        }
    }

}</style>
