<template>
  <div id="wrap">
    <div class="bar">
      <div class="item_box">
        <div class="notice">
          <img src="../assets/home/lb.png" alt="">
          <!-- <div class="tit">通知：</div> -->
          <div class="notice_con">
            <Slider :sliderData="sliderData" @getTitle="getSliderTitle" />
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="block">
        <el-carousel height="600px" :autoplay="false">
          <el-carousel-item v-for="(item, index) in imagelist" :key="index">
            <img :src="item.img" alt="">
            <div class="banner-box">
              <div class="box-left">
                <div class="left-logo">
                  <img style="width:120px;" src="../assets/home/logot.png" alt="">
                </div>
                <div class="right-text">
                  云图库 找 <span style="color: #F08519;">得然</span>
                </div>
              </div>
              <div class="box-right">
                <div class="rightimage">
                  <div class="boximg"><img style="width:25px;height: 25px;" src="../assets/home/dq.png" alt=""></div>
                  <div class="boximgs"><img style="width:25px;height:15px;" src="../assets/home/hq.png" alt=""></div>
                  <div class="boxtxt">{{ language }} <img style="width:10px;height: 10px;" src="../assets/home/lb.png"
                      alt=""></div>
                </div>
                <div class="rightlogin">
                  <div><img style="width:23px;height: 23px;" src="../assets/home/dl.png" alt=""></div>
                  <div @click="clicklogin">
                    登陆
                  </div>
                </div>
                <div class="registered" @click="clickRegistered">注册</div>
              </div>
            </div>

            <div class="banner-boxt">
              <div class="box-leftt">
                <div class="right-text">
                  <router-link to="/">首页</router-link>
                </div>
                <div class="right-text">
                  <router-link to="/copyrightservice">知识产权</router-link>
                </div>
                <div class="right-text">

                  <router-link to="/legaladvice">法律咨询</router-link>
                </div>
                <div class="right-text">
                  
                  <router-link to="/Originalcase">原创案例</router-link>
                </div>
                <div class="right-text">
                  <!-- 设计商城 -->
                  <router-link to="/Designmall">设计商城</router-link>
                </div>
                <div class="right-text">
                  <!-- 求职招聘 -->
                  <router-link to="/Jobserach">求职招聘</router-link>
                </div>
                <div class="right-text">
                  <!-- 硬件商城 -->
                   <router-link to="/Multimediamall">硬件商城</router-link>
                </div>
                <div class="right-text">
                  文创商城
                </div>
                <div class="right-text">
                  <!-- 软件天地 -->
                  <router-link to="/SoftwareWorld">软件天地</router-link>
                </div>
                <div class="right-text">
                  <!-- 牛人学院 -->
                  <router-link to="/Niurencollege">牛人学院</router-link>
                </div>
              </div>
              <div class="box-rightt">
                <div class="registered">VIP中心</div>
                <div class="registered">上传</div>
                <div class="registered">服务商工作台</div>
              </div>
            </div>

            <div class="banner-boxf">
              <div class="right-translation">
                The world's largest design service platf
              </div>
              <div class="right-platform">
                全球最大的设计服务平台
              </div>
            </div>
            <div class="banner-boxs">
              <div class="inputbox">
                <div>
                  <el-input v-model="input" prefix-icon="el-icon-search" suffix-icon="el-icon-picture"
                    placeholder="请输入想要了解的 公司/职位/人才/服务/案例/课程等"></el-input>
                </div>
                <div class="selectbtn">
                  <div>搜索/发布需求</div>
                </div>
              </div>
              <div class="historybox">
                <div class="historyall">
                  大家都在搜:
                </div>
                <div class="historyall">
                  <div class="articlebox" v-for="(item, index) in listdata" :key="index">{{ item.name }}</div>
                </div>
              </div>
              <div class="advisory">
                <div class="advisoryimg">
                  <img style="width: 15px;height:15px;" src="../assets/home/dl.png" alt="">
                </div>
                <div class="advisoryphone">
                  业务咨询与投诉：400-808-0006
                </div>
              </div>
            </div>

          </el-carousel-item>
        </el-carousel>

      </div>
    </div>
    <div>
      <Copyrightone />
      <Copyrighttwo />
      <Copyrightthree />
      <Copyrightfour />
      <Copyrightfive />
      <Copyrightsix />
      <Copyrightseven />
      <Copyrighteight />
      <Copyrightnine />
      <Copyrightten />
      <Copyfooter />
    </div>
  </div>
</template>
<script>
import Slider from '../components/rolling.vue'
import Copyrightone from '../components/Home/copyrightone'
import Copyrighttwo from '../components/Home/copyrighttwo.vue'
import Copyrightthree from '../components/Home/copyrightthree.vue'
import Copyrightfour from '../components/Home/copyrightfour.vue'
import Copyrightfive from '../components/Home/copyrightfive.vue'
import Copyrightsix from '../components/Home/copyrightsix.vue'
import Copyrightseven from '../components/Home/copyrightseven.vue'
import Copyrighteight from '../components/Home/copyrighteight.vue'
import Copyrightnine from '../components/Home/copyrightnine.vue'
import Copyrightten from '../components/Home/copyrightten.vue'
import Copyfooter from '../components/Home/copyfooter.vue'
export default {
  name: 'home',
  components: {
    Slider,
    Copyrightone,
    Copyrighttwo,
    Copyrightthree,
    Copyrightfour,
    Copyrightfive,
    Copyrightsix,
    Copyrightseven,
    Copyrighteight,
    Copyrightnine,
    Copyrightten,
    Copyfooter,
  },
  data() {
    return {
      input: '',
      listdata: [
        { name: '平面设计师' },
        { name: '家装设计' },
        { name: 'AI绘图' }
      ],
      sliderData: [
        {
          title: '1平面设计师'
        },
        {
          title: '2平面设计师'
        },
        {
          title: '3平面设计师'
        },
        {
          title: '4平面设计师'
        },
        {
          title: '5平面设计师'
        }
      ],
      imagelist: [
        {
          img: require('../assets/home/banner.png'),
          id: 0,
        },
        {
          img: require('../assets/home/banner.png'),
          id: 1,
        },
        {
          img: require('../assets/home/banner.png'),
          id: 2,
        }
      ],
      language: '简体中文',
      locationlist: [{
        ltlist: '简体中文',
        id: 0,
      }, {
        ltlist: '英文',
        id: 1,
      }],
    }
  },
  methods: {
    // 方法
    getSliderTitle(title) { // 获取点击的标题
      console.log('title:', title)
    },
    btnChange(e) {
      //将索引值赋值给activeChange
      this.activeChange = e.id;
      this.listname = e.ltlist
      this.getlocat = e.ltlist
      this.show = false
    },
    clicklogin() {
      this.$router.push('/Login')
    },
    clickRegistered() {
      this.$router.push('/Registered')
    },
    // clickservice() {
    //   // this.$router.push({path:'/copyrightservice',query:{isshow:true}})
    //   this.$router.push('/copyrightservice')
    // },
    // clicklegaladvice() {
    //   this.$router.push('/legaladvice')
    // },
  }
}
</script>
<style lang="less" scoped>
#wrap {
  width: 100%;

  .bar {
    background: #FFD493;
    height: 20px;
    width: 100%;

    .item_box {
      // border: 1px solid rgb(45, 248, 5);
      margin: 0 auto;
      width: 1200px;

      .notice {
        display: inline-flex;
        align-items: center;
        // padding: 0 100px;
        height: 20px;
        line-height: 20px;
        // overflow: hidden;
        // border: 1px solid hotpink;

        img {
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }

        // .tit {
        //   width: 20px;
        // }

        .notice_con {
          width: 93%;
          padding-right: 20px;
          white-space: nowrap;
          overflow: hidden;
          // border: 1px solid goldenrod;
        }
      }
    }
  }

  .banner {
    width: 100%;

    // height:600px;
    // border: 2px solid forestgreen;
    /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
      width: 5px;
      height: 5px;
      background: transparent;
      // border: 1px solid #ffffff;
      border-radius: 50%;
      opacity: 0.5;
    }

    /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
      width: 5px;
      height: 5px;
      background: #ffffff;
      border-radius: 50%;
      opacity: 1;
    }

    .block {
      position: relative;
      height: 100%;
    }

    .banner-box {
      width: 1200px;

      // border: 1px solid red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 10px;
      left: 357px;

      .box-left {
        // border: 1px solid rgb(255, 0, 0);
        // width: 250px;
        // height: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-logo {
          // border: 1px solid rgb(0, 26, 255);

        }

        .right-text {
          // border: 1px solid rgb(202, 255, 11);
          // width:150px;
          // height: 10px;
          color: #fff;
          font-size: 18px;

        }
      }

      .box-right {
        // border: 1px solid forestgreen;
        width: 280px;
        // height: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .rightimage {
          // width: 50px;
          display: flex;
          // border: 1px solid rgb(255, 0, 128);
          align-items: center;

          // justify-content: space-around;

          .boximg {
            width: 30px;
            height: 25px;
            // border: 1px solid rgb(7, 31, 252);
          }

          .boximgs {
            width: 30px;
            height: 15px;
            // border: 1px solid rgb(7, 31, 252);
          }

          .boxtxt {
            // border: 1px solid red;
            color: #fff;
          }
        }

        .rightlogin {
          // width: 20px;
          display: flex;
          color: #FFF;
          align-items: center;
          justify-content: space-around;
          // border: 1px solid rgb(0, 204, 255);
        }

        .registered {
          width: 40px;
          color: #FFF;
          height: 15px;
          background: #FF8D1A;
          border-radius: 20px;
          line-height: 15x;
          text-align: center;
          // border: 1px solid khaki;
        }
      }
    }

    .banner-boxt {
      width: 1200px;
      // border: 1px solid red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 50px;
      left: 357px;

      .box-leftt {
        width: 700px;
        // border: 1px solid forestgreen;
        // height: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          color: #fff;
          text-decoration: none;
        }

        .active {
          background-color: rgb(22, 122, 214);
          color: white;
        }

        .right-text {
          // border: 1px solid rgb(202, 255, 11);
          height: 20px;
          line-height: 20px;
          color: #E4E4E4;
          font-size: 15px;
          cursor: default;
        }
      }

      .box-rightt {
        // border: 1px solid forestgreen;
        width: 200px;
        // height: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .registered {
          // height: 10px;
          color: #E4E4E4;
          font-size: 3px;
          // line-height: 10px;
          // border: 1px solid khaki;
        }
      }
    }

    .banner-boxf {
      width: 1200px;
      // border: 1px solid rgb(222, 247, 0);
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-between;
      position: absolute;
      top: 200px;
      left: 357px;

      .right-translation {
        // width: 200px;
        // border: 1px solid rgb(230, 255, 5);
        height: 40px;
        color: #E4E4E4;
        font-size: 15px;
        line-height: 40px;
      }

      .right-platform {
        // width: 200px;
        // border: 1px solid forestgreen;

        color: #fff;
        font-size: 25px;
        // text-align: left;
      }
    }

    .banner-boxs {
      width: 1200px;
      // border: 1px solid red;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-between;
      position: absolute;
      top: 300px;
      left: 357px;

      .inputbox {
        // height: 10px;
        width: 450px;
        // display: flex;

        // border: 1px solid hotpink;
        // align-items:center;
        position: relative;

        .selectbtn {
          position: absolute;
          left: 470px;
          bottom: 7px;
          // border: 1px solid firebrick;
          width: 120px;
          text-align: center;
          height: 35px;
          line-height: 35px;
          // width:200px; 
          background: #FF8D1A;
          color: #fff;
          border-radius: 60px;
          font-size: 13px;
        }
      }

      .historybox {
        // width:400px;
        // border: 1px solid khaki;
        padding: 50px 0;
        display: flex;

        .historyall {
          // width:70px;
          height: 20px;
          line-height: 20px;
          // border: 1px solid forestgreen;
          color: #fff;
          display: flex;
          justify-content: space-around;
        }

        .articlebox {
          width: 80px;
          text-align: center;
          border-radius: 20px;
          border: 1px solid #ccc;
          margin: 0 10px;
        }
      }

      .advisory {
        // border: 1px solid khaki;
        padding: 80px 0;
        display: flex;

        .advisoryimg {
          // border: 1px solid salmon;

        }

        .advisoryphone {
          // width: 80px;
          text-align: center;
          // border-radius: 20px;
          color: #fff;
          padding: 0 10px;
          // margin: 0 10px;
        }
      }
    }

    /deep/ .el-input__inner {
      border-radius: 90px;
      width: 600px;
      height: 50px;
      // border: 2px solid forestgreen;
      // background: none;
    }
  }
}
</style>
