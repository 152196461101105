<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    专业的法律咨询服务
                </div>
                <div class="findlanguage">
                    Professional legal consulting services
                </div>
            </div>
            <div class="boxcopyright">
                <div class="famous">
                    著名律所
                </div>
                <div class="cardbox">
                    <el-carousel height="170px" autoplay="false">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <div class="cardcarousel">
                                <div class="cardcompany" v-for="(item, index) in listdata" :key="index">
                                    <div class="boxleft">
                                        <img style="width: 120px; height: 120px;" :src=item.img alt="">
                                    </div>
                                    <div class="boxright">
                                        <div class="boxtitle">山东某某律师事务所</div>
                                        <div class="boxlabel">
                                            <div class="labeltitle" v-for="(item, index) in labellist" :key="index">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                        <div class="boxarticle">
                                            山东某某律师事务所律师已为数万余法律需求者提供了免费的法律咨询，办理案件近万起。
                                        </div>
                                        <div class="more">
                                            查看更多>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="boxcopy">
                <div class="famous">
                    著名律师
                </div>
                <div class="cardboxt">
                    <el-carousel height="270px" autoplay="false">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <div class="cardcarousel">
                                <div class="cardcompany" v-for="(item, index) in listdatalawyer" :key="index">
                                    <div class="boxleft">
                                        <img style="width: 140px; height: 120px;" :src=item.img alt="">
                                    </div>
                                    <div class="boxright">
                                        <div class="toptitle">
                                            <div class="topname">张律师</div>
                                            <div class="topfulltime">专职律师</div>
                                        </div>
                                        <div class="boxtitle">山东某某律师事务所</div>
                                        <div class="boxlabel">
                                            <div class="labeltitle" v-for="(item, index) in labellist" :key="index">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                        <div class="more">
                                            在线咨询
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            labellist: [
                {
                    title: '成立10年',
                    id: 0,
                },
                {
                    title: '知识产权',
                    id: 1,
                },
                {
                    title: '刑事诉讼',
                    id: 2,
                },
                {
                    title: '合同纠纷',
                    id: 3,
                },
            ],
            listdata: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/fl.png'),
                    id: 2
                },

            ],
            listdatalawyer: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 2
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 3
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 4
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 5
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 6
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/ls.png'),
                    id: 7
                }
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #F4F4F4;
        height: 670px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #808080;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 220px;

            // background: salmon;
            .famous {
                // border: 1px solid green;
                width: 300px;
                color: #000;
                font-weight: bold;
                font-size: 16px;
            }

            .cardbox {
                width: 1250px;
                // height: 300px;
                background: none;
                margin: 10px 0;

                // border: 1px solid khaki;
                // display: flex;
                // flex-direction: row;
                .cardcarousel {
                    width: 1200px;
                    height: 200px;
                    // border: 1px solid rgb(255, 217, 0);
                    display: flex;
                    flex-direction: row;

                    .cardcompany {
                        width: 390px;
                        height: 150px;
                        margin: 10px auto;
                        border: 1px solid rgb(54, 54, 54);
                        display: flex;
                        justify-content: space-between;

                        .boxleft {
                            width: 140px;
                            height: 150px;
                            // border: 1px solid firebrick;
                            padding: 10px 10px;
                        }

                        .boxright {
                            width: 260px;
                            height: 150px;
                            // border: 1px solid rgb(126, 252, 9);

                            .boxtitle {
                                padding: 10px 5px;
                                font-size: 18px;
                                font-weight: bold;
                            }

                            .boxlabel {
                                // width:220px;
                                // border: 1px solid firebrick;
                                display: flex;
                                justify-content: space-around;

                                .labeltitle {
                                    width: 60px;
                                    border-radius: 5px;
                                    border: 1px solid #99A4B3;
                                    font-size: 10px;
                                    text-align: center;
                                }
                            }

                            .boxarticle {
                                // width: 100px;
                                // border: 1px solid khaki;
                                padding: 10px 5px;
                            }

                            .more {
                                // border: 1px solid salmon;
                                // width: 60px;
                                text-align: end;
                                padding: 0 10px;
                            }
                        }

                    }
                }
            }

        }

        .boxcopy {
            width: 1200px;
            margin: 0 auto;
            // height: 300px;

            // background: salmon;
            .famous {
                // border: 1px solid green;
                width: 300px;
                color: #000;
                font-weight: bold;
                font-size: 16px;
            }

            .cardboxt {
                width: 1250px;
                // height: 300px;
                background: none;
                margin: 10px 0;


                // display: flex;
                // flex-direction: row;
                .cardcarousel {
                    width: 1200px;
                    // height: 300px;
                    // border:1px solid rgb(0, 255, 34);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .cardcompany {
                        width: 140px;
                        // height: 150px;
                        // margin: 10px auto;
                        // border: 1px solid rgb(54, 54, 54);
                        // display: flex;
                        // justify-content: space-between;
                        border: 1px solid #C9C9C9;
                        box-shadow: 1px 2px 3px #c5c5c4;

                        .boxleft {
                            width: 140px;
                            // height:120px;
                            // border:1px solid firebrick;
                            // padding: 10px 10px;
                        }

                        .boxright {
                            width: 140px;

                            height: 140px;
                            // border:1px solid rgb(245, 159, 0);
                            background: #fff;

                            .toptitle {
                                width: 140px;
                                //    border: 1px solid sandybrown;
                                display: flex;
                                justify-content: space-around;
                                align-items: center;

                                .topname {
                                    // border: 1px solid darkcyan;
                                    font-size: 17px;
                                    font-weight: bold;
                                }

                                .topfulltime {
                                    // border: 1px solid darkcyan;
                                    width: 70px;
                                    background: #798493;
                                    border-radius: 10px;
                                    // padding: 5px 0;
                                    text-align: center;
                                    line-height: 20px;
                                    height: 20px;
                                    color: #fff;
                                    font-size: 10px;
                                }
                            }

                            .boxtitle {
                                padding: 5px 7px;
                                // font-size: 10px;
                                // font-weight: bold;
                                // border: 1px solid rgb(255, 0, 0);
                                // background: lawngreen;
                            }

                            .boxlabel {
                                width: 130px;
                                // border: 1px solid rgb(35, 252, 6);
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                padding: 10px 0;

                                .labeltitle {
                                    width: 50px;
                                    border-radius: 5px;
                                    border: 1px solid #99A4B3;
                                    font-size: 10px;
                                    text-align: center;
                                }
                            }


                            .more {
                                // border: 1px solid salmon;
                                // width: 60px;
                                text-align: center;
                                padding: 5px 0;
                                width: 100px;
                                height: 15px;
                                border-radius: 5px;
                                background: #E58F3C;
                                line-height: 15px;
                                margin: 0 auto;
                                color: #fff;

                            }
                        }

                    }
                }
            }

        }
    }

}</style>
