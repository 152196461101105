<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    海量IP的文创商城
                </div>
                <div class="findlanguage">
                    A cultural and creative mall with massive IPs
                </div>
            </div>
            <div class="boxcopyright">
                <div class="boxleft">
                    <div class="onetitle">
                        文创产品
                    </div>
                </div>
                <div class="boxright">
                    <div class="boxtop">
                        <div class="onetitle">
                            纪念品
                        </div>
                    </div>
                    <div class="boxbottom">
                        <div class="wrapall">
                            <div class="boxfont">IP形象</div>
                            <div class="boxfont">更多></div>
                        </div>
                        <div class="bottomimg">
                            <div v-for="(item, index) in imagelist" :key="index">
                                <img style="width: 90px;height:90px;" :src=item.img alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            imagelist: [
                {
                    img: require('../../assets/home/sidethree.png'),
                    id: 0
                },
                {
                    img: require('../../assets/home/bottomthree.png'),
                    id: 1
                },
                {
                    img: require('../../assets/home/bottomfour.png'),
                    id: 2
                },
                {
                    img: require('../../assets/home/bottomfive.png'),
                    id: 3
                }
            ],
            listdata: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/one.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/two.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/three.png'),
                    id: 2
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/four.png'),
                    id: 3
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/five.png'),
                    id: 4
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/six.png'),
                    id: 5
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/seven.png'),
                    id: 6
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/eight.png'),
                    id: 7
                }
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #F5EFE9;
        height: 670px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #000;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 450px;
            // background: salmon;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            // border: 1px solid firebrick;

            .boxleft {
                width: 570px;
                height: 450px;
                border-radius: 10px;
                background-image: url('../../assets/home/sideone.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;

                .oneimg {
                    padding: 20px 20px;
                    // border: 1px solid magenta;
                }

                .onetitle {
                    padding: 10px 15px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                }

            }

            .boxright {
                width: 570px;
                height: 450px;
                border-radius: 10px;
                // border: 1px solid navy;

                .boxtop {
                    width: 570px;
                    height: 310px;
                    border-radius: 10px;
                    background-image: url('../../assets/home/bottomtwo.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    // border: 1px solid magenta;

                    .onetitle {
                        padding: 10px 15px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .boxbottom {
                    width: 560px;
                    height: 140px;
                    // border: 1px solid saddlebrown;

                    .wrapall {
                        // border: 1px solid saddlebrown;
                        display: flex;
                        justify-content: space-between;
                        width: 540px;
                        height:20px;
                        padding: 10px 10px;

                        .boxfont {
                            font-size: 14px;
                            // border: 1px solid forestgreen;

                        }

                    }


                    .bottomimg {
                        // border: 1px solid darkcyan;
                        width: 540px;
                        height:70px;
                        display: flex;
                        justify-content: space-between;
                        padding: 15px 10px;
                        align-items: center;
                       
                    }
                }
            }
        }
    }

}
</style>
