<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    线上求职与招聘服务
                </div>
                <div class="findlanguage">
                    Online job search and recruitment services
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox">
                    <div class="onetop">
                        <h1>我是企业</h1>
                        <p class="onetitle">在网站上提供公司信息，发布招聘公告以寻求合适的
                            设计师</p>
                    </div>
                    <div class="onebottom">
                        <div class="bottomleft">招聘发布</div>
                        <div class="bottomright">
                            <div class="inputbox">
                                <div>
                                    <el-input v-model="input" prefix-icon="el-icon-search"   placeholder="搜索简历"></el-input>
                                </div>
                                <div class="selectbtn">
                                    <div>搜索</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="allbox">
                    <div class="onetop">
                        <h1>我是设计师</h1>
                        <p class="onetitle">在网站上提供公司信息，发布招聘公告以寻求合适的
                            设计师</p>
                    </div>
                    <div class="onebottom">
                        <div class="bottomleft">简历制作</div>
                        <div class="bottomright">
                            <div class="inputbox">
                                <div>
                                    <el-input v-model="input" placeholder="搜索公司"></el-input>
                                </div>
                                <div class="selectbtn">
                                    <div>搜索</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background-image: url('../../assets/home/sidefour.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 580px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #fff;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #fff;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 350px;
            // border: 1px solid khaki;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            .allbox {
                width: 550px;
                height: 300px;
                border-radius: 10px;
                background: #929090;
                background-color:rgba(228, 248, 252, 0.2);
            
                .onetop {
                    padding: 20px 40px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    // border: 1px solid magenta;

                    .onetitle {
                        // width:250px;
                        font-size: 15px;
                        // border: 1px solid darkcyan;
                    }
                }

                .onebottom {
                    // padding: 30px 20px;
                    width: 475px;
                    color: #fff;
                    font-size: 12px;
                    // border: 1px solid khaki;
                    margin: 0 auto;
                    display: flex;
                    justify-content:space-between;
                    align-items: center;

                    .bottomleft {
                        width: 100px;
                        border: 1px solid rgb(255, 255, 255);
                        height: 30px;
                        border-radius: 50px;
                        text-align: center;
                        line-height: 30px;
                        color: #fff;
                    }

                    .bottomright {
                        width: 300px;
                        height: 40px;
                        // border: 1px solid darkblue;
                        .inputbox {
                            width: 300px;
                            position: relative;
                            border-radius: 60px;

                            .selectbtn {
                                position: absolute;
                                left: 175px;
                                top: 2px;
                                // border: 1px solid firebrick;
                                width: 120px;
                                text-align: center;
                                height: 35px;
                                line-height: 35px;
                                // width:200px; 
                                background: #FF8D1A;
                                color: #fff;
                                border-radius: 60px;
                                font-size: 13px;
                            }
                        }

                        /deep/ .el-input__inner {
                            border-radius: 90px;
                            width:300px;
                            // height: 50px;
                            // border: 2px solid forestgreen;
                            // background: none;
                        }
                    }

                }
            }
        }
    }

}</style>
