<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    设计软件大全
                </div>
                <div class="findlanguage">
                    Complete collection of design software
                </div>
            </div>
            <div class="boxcopyright">
                <div class="cardboxt">
                    <el-carousel height="270px" autoplay="false">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <div class="cardcarousel">
                                <div class="cardcompany">
                                    <div class="boxleft">
                                        <p class="lefttitle">3D建模</p>
                                    </div>
                                    <div class="toptitle">了解更多></div>
                                </div>
                                <div class="cardcompanytwo">
                                    <div class="boxleft">
                                        <p class="lefttitle">CAD制图</p>
                                    </div>
                                    <div class="toptitle">了解更多></div>
                                </div>
                                <div class="cardcompanythree">
                                    <div class="boxleft">
                                        <p class="lefttitle">图像处理</p>
                                    </div>
                                    <div class="toptitle">了解更多></div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            listdata: [
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/one.png'),
                    id: 0
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/two.png'),
                    id: 1
                },
                {
                    title: '专业的版权知产服务',
                    article: '帮助企业维护权益以及申请资质 以提高企业',
                    img: require('../../assets/home/three.png'),
                    id: 2
                },


            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #fff;
        height: 500px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #000;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 300px;
            // border: 1px solid navajowhite;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            .cardboxt {
                width: 1250px;
                // height: 300px;
                background: none;
                margin: 10px 0;


                // display: flex;
                // flex-direction: row;
                .cardcarousel {
                    width: 1200px;
                    // height: 300px;
                    // border:1px solid rgb(0, 255, 34);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .cardcompany {
                        width: 350px;
                        height: 350px;
                        // border: 1px solid firebrick;
                        background-image: url('../../assets/home/sixnine.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;

                        .boxleft {
                            width: 350px;
                            height:215px;
                            // border: 1px solid firebrick;
                            // padding: 10px 10px;

                            .lefttitle {
                                // border: 1px solid wheat;
                                text-align: center;
                                color: #fff;
                                font-size: 25px;
                                padding: 75px 0;
                            }

                        }
                        .toptitle {
                            // border: 1px solid hotpink;
                            width: 350px;
                            height: 35px;
                            line-height: 35px;
                            text-align: end;
                            color: #fff;
                            background: #505761;
                            // opacity: .6;
                            background-color:rgba(7, 7, 7, 0.2);
                        }

                    }
                    .cardcompanytwo {
                        width: 350px;
                        height: 350px;
                        // border: 1px solid firebrick;
                        background-image: url('../../assets/home/sideten.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;

                        .boxleft {
                            width: 350px;
                            height:215px;
                            // border: 1px solid firebrick;
                            // padding: 10px 10px;

                            .lefttitle {
                                // border: 1px solid wheat;
                                text-align: center;
                                color: #fff;
                                font-size: 25px;
                                padding: 75px 0;
                            }

                        }
                        .toptitle {
                            // border: 1px solid hotpink;
                            width: 350px;
                            height: 35px;
                            line-height: 35px;
                            text-align: end;
                            color: #fff;
                            background: #505761;
                            background-color:rgba(7, 7, 7, 0.2);
                        }

                    }
                    .cardcompanythree {
                        width: 350px;
                        height: 350px;
                        // border: 1px solid firebrick;
                        background-image: url('../../assets/home/bottomone.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;

                        .boxleft {
                            width: 350px;
                            height:215px;
                            // border: 1px solid firebrick;
                            // padding: 10px 10px;

                            .lefttitle {
                                // border: 1px solid wheat;
                                text-align: center;
                                color: #fff;
                                font-size: 25px;
                                padding: 75px 0;
                            }

                        }
                        .toptitle {
                            // border: 1px solid hotpink;
                            width: 350px;
                            height: 35px;
                            line-height: 35px;
                            text-align: end;
                            color: #fff;
                            background: #505761;
                            background-color:rgba(7, 7, 7, 0.2);
                        }

                    }
                }

            }
        }
    }


}
</style>
