<template>
    <div id="wrap">
        <div class="articlebox">
            <div style="height: 90px;"></div>
            <div class="boxcopyright">
                <div class="allbox" v-for="(item, index) in listdata" :key="index">
                    <div v-if="item.name == '关注得然网'">
                        <h1 class="tabletitle">关注得然网</h1>
                        <div class="tableplatform">
                            <div>
                                <p style="width:70px;height: 70px;background:#BAC5D4;"></p>
                                <p style="color: #fff;text-align: center;">得然APP</p>
                            </div>
                            <div>
                                <p style="width: 70px;height: 70px;background:#BAC5D4;"></p>
                                <p style="color: #fff;text-align: center;"> 微信公众号</p>
                            </div>
                            <div>
                                <p style="width:70px;height: 70px;background:#BAC5D4;"></p>
                                <p style="color: #fff;text-align: center;">微信小程序</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h1 class="tabletitle">{{ item.name }}</h1>
                        <div class="tablearticle" v-for="(items, index) in item.tablelist" :key="index">
                            {{ items.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrighttwo',
    data() {
        return {
            listdata: [{
                name: '业务产品',
                id: 0,
                tablelist: [
                    {
                        title: '知识产权',
                        id: 0
                    },
                    {
                        title: '原创案例',
                        id: 1
                    },
                    {
                        title: '设计商城',
                        id: 1

                    },
                    {
                        title: '多媒体商城',
                        id: 1

                    },
                    {
                        title: '牛人学院',
                        id: 1

                    },
                    {
                        title: '软件天地',
                        id: 1

                    },
                    {
                        title: '求职招聘',
                        id: 1

                    },
                    {
                        title: '公司风采',
                        id: 1
                    }
                ]
            },
            {
                name: '服务|支持',
                id: 1,
                tablelist: [
                    {
                        title: '平台保障',
                        id: 0
                    },
                    {
                        title: '新人指南',
                        id: 1
                    },
                    {
                        title: '新人福利',
                        id: 2

                    },
                    {
                        title: '雇主帮助中心',
                        id: 3

                    },
                    {
                        title: '支付方式',
                        id: 4

                    },
                ]
            }
                ,
            {
                name: '规则|反馈',
                id: 2,
                tablelist: [
                    {
                        title: '规则反馈',
                        id: 0
                    },
                    {
                        title: '平台信息公示区',
                        id: 1
                    },
                    {
                        title: '隐私保护政策',
                        id: 2

                    },
                    {
                        title: '客服反馈',
                        id: 3

                    },
                    {
                        title: '得然110举报',
                        id: 4

                    },
                    {
                        title: '交易纠纷调解室',
                        id: 5

                    },
                    
                ]
            }
                ,
            {
                name: '关于我们',
                id: 3,
                tablelist: [
                    {
                        title: '公司信息',
                        id: 0
                    },
                    {
                        title: '公司资质',
                        id: 1
                    },
                    {
                        title: '公司理财',
                        id: 2

                    },
                    {
                        title: '业务范畴',
                        id: 3

                    },
                    {
                        title: '联系我们',
                        id: 4

                    },
                    {
                        title: '办公地点',
                        id: 5

                    },
                    
                ]
            }

                ,
            {
                name: '更多推荐',
                id: 4,
                tablelist: [
                    {
                        title: '雇主PLUS',
                        id: 0
                    },
                    {
                        title: '雇主外包项目大厅',
                        id: 1
                    },
                    {
                        title: '我的企业',
                        id: 2

                    },
                    {
                        title: '得然会员',
                        id: 3

                    },
                    {
                        title: '客服推广',
                        id: 4
                    },
                    {
                        title: '用工合伙人',
                        id: 5

                    },
                ],

            },
            {
                name: '关注得然网',
                id: 5,
                tablelist: [
                    {
                        title: '得然APP',
                        id: 0

                    },
                    {
                        title: '微信公众号',
                        id: 1

                    },
                    {
                        title: '微信小程序',
                        id: 2

                    }
                ]
            }
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #383838;
        height: 500px;

        .boxcopyright {
            width: 1200px;
            height: 300px;
            margin: 0 auto;
            // border: 1px solid khaki;
            display: flex;
            justify-content: space-between;

            .allbox {
                width: 200px;
                // height: 200px;
                // border: 1PX solid rgb(102, 255, 0);
                text-align: left;

                .tabletitle {
                    color: #fff;
                }

                .tablearticle {
                    color: #fff;
                    height:30px;
                    line-height: 30px;

                }

                .tableplatform {
                    // border: 1px solid rebeccapurple;
                    // height:260px;
                    // width: 180px;
                    // margin: 0 auto;
                    display: flex;
                    justify-content:space-between;
                    flex-wrap: wrap;
                }
            }
        }
    }

}
</style>
