<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    内容丰富的设备商城
                </div>
                <div class="findlanguage">
                    A diverse equipment mall
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox" v-for="(item, index) in listdata" :key="index">
                    <div class="oneimg">
                        <img style="width:250px;height: 200px;" :src=item.img alt="">
                    </div>
                    <div class="onetitle">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            listdata: [
                {
                    title: '多媒体集成设备   ',
                    img: require('../../assets/home/sidefive.png'),
                    id: 0
                },
                {
                    title: '设计设备',
                    img: require('../../assets/home/sidesix.png'),
                    id: 1
                },
                {
                    title: '智能控制设备',
                    img: require('../../assets/home/sideseven.png'),
                    id: 2
                },
                {
                    title: '其他定制',
                    img: require('../../assets/home/sixeight.png'),
                    id: 3
                },

            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #fff;
        height: 400px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #000;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height:270px;
            // background: salmon;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            // border: 1px solid forestgreen;

            .allbox {
                width: 250px;
                height: 240px;
                border-radius: 10px;
                // background: #666666;
                // border: 1px solid firebrick;

                .oneimg {
                    // padding: 20px 20px;
                    // border: 1px solid magenta;
                }

                .onetitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    padding: 5px 0;
                }
            }
        }
    }

}
</style>
