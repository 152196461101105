<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    种类齐全的设计商城
                </div>
                <div class="findlanguage">
                    Find the design services you need
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox">
                    <div class="boxleft">
                        <div class="boxtitle">
                            方案定制
                        </div>
                        <div class="tableall">
                            <div class="tablebox" v-for="(item, index) in listdata" :key="index">
                                <div class="tableleft">

                                </div>
                                <div class="tableright">
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="boxright">
                        <div class="boxtitle">
                            线上接单
                        </div>
                        <div class="tableall">
                            <div class="tablebox" v-for="(item, index) in listdata" :key="index">
                                <div class="tableleft">

                                </div>
                                <div class="tableright">
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="allboxbottom">
                    <div class="cardboxt">
                        <el-carousel height="300px" autoplay="false">
                            <el-carousel-item v-for="item in 4" :key="item">
                                <div class="cardcarousel">
                                    <div class="cardcompany" v-for="(item, index) in listdatalawyer" :key="index">
                                        <div class="boxleft">
                                            <img style="width: 140px; height: 120px;" :src=item.img1 alt="">
                                        </div>
                                        <div class="boxright">
                                            <div class="boxtitle">{{ item.name }}</div>
                                            <div class="boxlabel">
                                                <img style="width: 100px; height: 100px;" :src=item.img2 alt="">
                                            </div>
                                            <div class="wraptitle">
                                                {{ item.name2 }}
                                            </div>
                                            <div class="wraparticle">
                                                {{ item.article }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            listdata: [
                {
                    title: '建筑设计',
                    id: 0,
                    img: require('../../assets/home/tableimg.png')
                },
                {
                    title: '家装设计',
                    id: 1
                },
                {
                    title: '工装设计',
                    id: 2
                },
                {
                    title: '园林景观',
                    id: 3
                },
                {
                    title: '规划设计',
                    id: 4
                },
                {
                    title: '展馆展厅',
                    id: 5
                },
                {
                    title: '美陈设计',
                    id: 6
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
                {
                    title: '美陈设计',
                    id: 6
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
            ],
            listdatalawyer: [
                {
                     img1: require('../../assets/home/topeight.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middleseven.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:0,
                 },
                 {
                     img1: require('../../assets/home/topone.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middleone.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:1,
                 },
                 {
                     img1: require('../../assets/home/topsix.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middletwo.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:2,
                 },
                 {
                     img1: require('../../assets/home/topthree.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middlethree.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:3,
                 },{
                     img1: require('../../assets/home/topfour.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middle.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:4,
                 },{
                     img1: require('../../assets/home/topfive.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middlefour.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:5,
                 },{
                     img1: require('../../assets/home/topsix.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middlefive.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:6,
                 },{
                     img1: require('../../assets/home/topseven.png'),
                     name:'企业名称',
                     img2: require('../../assets/home/middlesix.png'),
                     name2:'首席设计师',
                     article:'建筑设计师',
                     id:7,
                 },
                
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #F4F4F4;
        height: 750px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #000;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 620px;
            // border: 1px solid forestgreen;

            .allbox {
                width: 1200px;
                height: 300px;
                // border: 1px solid firebrick;
                display: flex;
                justify-content: space-between;

                .boxleft {
                    width: 580px;
                    height: 300px;
                    border-radius: 20px;
                    background-image: url("../../assets/home/topnine.png");

                    .boxtitle {
                        // border: 1px solid firebrick;
                        text-align: center;
                        font-size: 20px;
                        font-weight: bold;
                        color: #fff;
                        padding: 30px 0;

                    }

                    .tableall {
                        // border: 1px solid saddlebrown;
                        width: 580px;

                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .tablebox {
                            // border: 1px solid darkblue;
                            width: 70px;
                            height: 40px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;

                            .tableleft {
                                width: 5px;
                                height: 15px;
                                background: #fff
                            }

                            .tableright {
                                color: #fff;
                            }
                        }


                    }
                }

                .boxright {
                    width: 580px;
                    height: 300px;
                    border-radius: 20px;
                    background-image: url("../../assets/home/topten.png");

                    .boxtitle {
                        // border: 1px solid firebrick;
                        text-align: center;
                        font-size: 20px;
                        font-weight: bold;
                        color: #fff;
                        padding: 30px 0;

                    }

                    .tableall {
                        // border: 1px solid saddlebrown;
                        width: 580px;

                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .tablebox {
                            // border: 1px solid darkblue;
                            width: 70px;
                            height: 40px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;

                            .tableleft {
                                width: 5px;
                                height: 15px;
                                background: #fff
                            }

                            .tableright {
                                color: #fff;
                            }
                        }


                    }
                }

            }

            .allboxbottom {
                // border: 1px solid darkcyan;
                .cardboxt {
                width: 1200px;
                // height: 300px;
                background: none;
                margin: 10px 0;
                // border:2px solid #E58F3C;

                // display: flex;
                // flex-direction: row;
                .cardcarousel {
                    width: 1200px;
                    // height: 300px;
                    // border:1px solid rgb(0, 255, 34);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .cardcompany {
                        width: 140px;
                        // height: 150px;
                        // margin: 10px auto;
                        // border: 1px solid rgb(54, 54, 54);
                        // display: flex;
                        // justify-content: space-between;
                        // border: 1px solid #C9C9C9;
                        box-shadow: 1px 2px 3px #c5c5c4;
                        background: #fff;
                        border-radius: 10px;

                        .boxleft {
                            width: 140px;
                            // height:120px;
                            // border:1px solid firebrick;
                            // padding: 10px 10px;
                        }

                        .boxright {
                            width: 140px;

                            // height: 170px;
                            // border:1px solid rgb(245, 159, 0);
                            background: #fff;
                            border-radius: 20px;

                            .toptitle {
                                width: 140px;
                                //    border: 1px solid sandybrown;
                                display: flex;
                                justify-content: space-around;
                                align-items: center;

                                .topname {
                                    // border: 1px solid darkcyan;
                                    font-size: 17px;
                                    font-weight: bold;
                                }

                                .topfulltime {
                                    // border: 1px solid darkcyan;
                                    width: 70px;
                                    background: #798493;
                                    border-radius: 10px;
                                    // padding: 5px 0;
                                    text-align: center;
                                    line-height: 20px;
                                    height: 20px;
                                    color: #fff;
                                    font-size: 10px;
                                }
                            }

                            .boxtitle {
                                // padding: 5px 7px;
                                // font-size: 10px;
                                // font-weight: bold;
                                // border: 1px solid rgb(255, 0, 0);
                                // background: lawngreen;
                                text-align: center;
                            }

                            .boxlabel {
                                width: 130px;
                                // border: 1px solid rgb(35, 252, 6);
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                padding:5px 0;

                                .labeltitle {
                                    width: 50px;
                                    border-radius: 5px;
                                    border: 1px solid #99A4B3;
                                    font-size: 10px;
                                    text-align: center;
                                }
                            }


                            .wraptitle {
                                // border: 1px solid salmon;
                                // width: 60px;
                                text-align: center;
                                padding: 5px 0;
                                width: 100px;
                                height: 15px;
                                // border-radius: 5px;
                                // background: #E58F3C;
                                line-height: 15px;
                                margin: 0 auto;
                                font-size: 15px;
                                // color: #fff;
                                

                            }
                            .wraparticle{
                                font-size: 10px;
                                text-align: center;
                            }
                        }

                    }
                }
            }
            }
        }
    }

}
</style>
