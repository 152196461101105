<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    在得然, 您可以找到
                </div>
                <div class="findlanguage">
                    At Deran, you can find
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox" v-for="(item,index) in listdata" :key="index">
                   <div class="oneimg">
                    <img :src=item.img alt="">
                    <div class="onetitle">
                     {{ item.title }}
                    </div>
                   </div>
                   <div class="onearticle">
                    {{ item.article }}
                   </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
           listdata:[
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/one.png'),
            id:0
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/two.png'),
            id:1
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/three.png'),
            id:2
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/four.png'),
            id:3
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/five.png'),
            id:4
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/six.png'),
            id:5
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/seven.png'),
            id:6
           },
           {
            title:'专业的版权知产服务',
            article:'帮助企业维护权益以及申请资质 以提高企业',
            img:require('../../assets/home/eight.png'),
            id:7
           }
           ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #383838;
        height: 670px;
        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #fff;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #fff;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height:550px;
            // background: salmon;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            .allbox{
                width:250px;
                height:240px;
                border-radius: 10px;
                background: #666666;
                .oneimg{
                    padding:20px 20px;
                    // border: 1px solid magenta;
                }
                .onetitle{
                    padding:4px 0;
                    color:#fff;
                    font-size: 16px;
                    font-weight: bold;
                }
                .onearticle{
                    padding: 30px 20px;
                    color:#fff;
                    font-size: 12px;
                }
            }
        }
    }

}</style>
