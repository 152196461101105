<template>
    <div id="wrap">
        <div class="articlebox">
            <div class="artcletitle">
                <div class="findtxt">
                    海量原创案例分享
                </div>
                <div class="findlanguage">
                    Massive original case sharing
                </div>
            </div>
            <div class="boxcopyright">
                <div class="allbox">
                    <div class="boxtop">
                        <div class="toptitle">
                            <div><img style="width:20px;height: 20px;" src="../../assets//home/search.png" alt=""></div>
                            <div class="topupload">
                                <div> <img style="width:20px;height: 20px;" src="../../assets/home/upload.png" alt="">
                                </div>
                                点击上传
                            </div>
                        </div>
                        <div class="boxtext">
                            案例导览
                        </div>
                    </div>
                    <div class="boxbottom">
                        <div class="bottomtitle" v-for="(item, index) in listdata" :key="index" >
                           <div v-if="!item.img">
                            {{ item.title }}
                           </div>
                           <div v-else>
                           <img style="width:197px;height: 150px;" :src=item.img alt="">
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copyrightone',
    data() {
        return {
            listdata: [
                {
                    title: '建筑设计',
                    id: 0,
                    img:require('../../assets/home/tableimg.png')
                },
                {
                    title: '家装设计',
                    id: 1
                },
                {
                    title: '工装设计',
                    id: 2
                },
                {
                    title: '园林景观',
                    id: 3
                },
                {
                    title: '规划设计',
                    id: 4
                },
                {
                    title: '展馆展厅',
                    id: 5
                },
                {
                    title: '美陈设计',
                    id: 6
                },
                {
                    title: '雕塑设计',
                    id: 7
                },
                {
                    title: 'UI设计',
                    id: 8
                },
                {
                    title: '品牌设计',
                    id: 9
                },
                {
                    title: 'LOGO设计',
                    id: 10
                },
                {
                    title: '海报设计',
                    id: 11
                },
            ]
        }
    }
}

</script>
<style lang="less" scoped>
#wrap {
    width: 100%;

    .articlebox {
        background: #fff;
        height: 670px;

        .artcletitle {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;

            .findtxt {
                color: #000;
                font-size: 20px;
                height: 30px;
                font-weight: bold;
                // border: 1px solid gold;
            }

            .findlanguage {
                color: #000;
                font-size: 13px;
            }
        }

        .boxcopyright {
            width: 1200px;
            margin: 0 auto;
            height: 550px;
            background-image: url("../../assets/home/bannertwo.png");
            background-size: 100% 93%;
            background-repeat: no-repeat;

            .allbox {
                width: 1200px;
                height: 550px;
                // border: 1px solid darkcyan;

                .boxtop {
                    // border: 1px solid firebrick;
                    height: 200px;

                    .toptitle {
                        // border: 1px solid darkblue;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 20px;

                        .topupload {
                            // border: 1px solid red;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .boxtext {
                        font-size: 30px;
                        text-align: center;
                        padding: 60px 0;
                        // border: 1px solid darkcyan;
                    }
                }


                .boxbottom {
                    // border: 1px solid firebrick;
                    height: 300px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    // padding: 0 20px;

                    .bottomtitle {
                        width: 197px;
                        height: 150px;
                        border: 1px solid #D3D7D9;
                        text-align: center;
                        line-height: 150px;
                        font-size: 15px;
                    }
                }
            }
        }
    }

}</style>
